module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"38af604999f8fd2212b2f2644cf08bc6"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"omitPrismicScript":true,"repositoryName":"menigaiceland","defaultLang":"is","langs":["is","en-gb"],"path":"/preview","previews":true,"pages":[{"type":"Page","match":"/:lang?/:uid","path":"/page-preview","component":"/opt/build/repo/src/templates/page.js"},{"type":"About","match":"/:lang?/:uid","path":"/about-preview","component":"/opt/build/repo/src/templates/about.js"},{"type":"Blog_Listing","match":"/:lang?/blog","path":"/blog-preview","component":"/opt/build/repo/src/templates/blog.js"},{"type":"Article","match":"/:lang?/blog/:uid","path":"/blog-preview","component":"/opt/build/repo/src/templates/article.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N8FD6JX","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
