// In src/prismic-configuration.js

import * as data from '../../prismic/pages-final.json'

export const linkResolver = (doc) => {
    if ( doc.uid === 'home' ) return doc.lang ? doc.lang : '/';
    if ( ! data.default[ doc.type ] ) return '/broken';

    let find = data.default[ doc.type ][ doc.id ];
    let path = find ? `${find.path}` : doc.uid;

    return path ? path : '/';
}
